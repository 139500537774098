import * as React from "react"

import Layout from "../components/Layout"

import Seo from "../components/Seo"

import Section from "../components/Section"

import AnimatedHeading from "../components/AnimatedHeading"

import AnimatedBlock from "../components/AnimatedBlock"

import "../css/index.css"
import "../css/pravilaPrivatnosti.css"

import backgroundImage from "../images/heading-background.jpg"

const PravilaPrivatnostiPage = () => (
  <Layout>
    <Seo title="Pravila privatnosti" />
    <Section
      className="kn-first-section"
      backgroundImage={backgroundImage}
      id="Pravila Privatnosti"
    >
      <div className="kn-first-section-title-container">
        <AnimatedHeading
          className="kn-first-section-title-ah"
          delay={400}
          delayBetween={20}
        >
          <h1 className="kn-first-section-title">
            IZJAVA O ZAŠTITI PRIVATNOSTI I SIGURNOSTI OSOBNIH PODATAKA
          </h1>
        </AnimatedHeading>
      </div>
    </Section>

    <Section
      className="kn-third-section"
      animation={[
        {
          top: "50%",
          left: "0%",
          transform: "translate( 0%, 0%)",
          width: "100%",
          height: "auto",
          padding: "0",
          opacity: "0",
          transition: " all 0.3s 0.3s ease",
        },
        {
          top: "0%",
          left: "0%",
          transform: "translate( 0%, 0%)",
          padding: "50px 0px",
          opacity: "1",
          transition: "all 0.3s 0.2s ease",
        },
      ]}
      id="Pravila Privatnosti tekst"
    >
      <div className="kn-third-section-title-container">
        <AnimatedBlock
          className="kn-third-section-content-ab kn-pravila-privatnosti"
          delay={300}
        >
          <p>
            Geodioba d.o.o. posvećuje veliku važnost zaštiti osobnih podataka
            naših posjetitelja. Ta politika zaštite privatnosti koje Geodioba
            d.o.o. provodi uređuje odnos prema informacijama koje prikupljamo
            putem naših web stranica.
          </p>
          <br />
          <br />
          <p>
            Osobni podaci su oni podaci koji mogu Vas identificirati, kao što su
            ime i prezime, telefonski broj ili email adresa (u nastavku teksta:
            osobni podaci).
          </p>
          <br />
          <br />
          <p>
            Pregledavanje naše internetske stanice je anonimno te se tom
            prilikom ne prikupljaju podaci koji bi omogućili Vašu osobnu
            identifikaciju. No Geodioba d.o.o. automatski prikuplja podatke o
            Vašem računalu. Ti podaci mogu uključivati Vašu IP adresu, tip
            preglednika, državu, platformu koju koju koristite, vrijeme
            pristupa. Ove podatke Geodioba d.o.o. koristi u svrhu sakupljanja
            statističkih podataka, analize posjetitelja i poboljšanja iskustva
            pristupa stranici.
          </p>
          <br />
          <br />
          <p>
            Geodioba d.o.o. može, u posebnim slučajevima, prikupljati osobne
            podatke kada su nam ti podaci potrebni poradi e-prijava na javne
            pozive ili natječaje i slično. Tom prilikom točno je određena svrha
            prikupljanja osobnih podataka i ti se podaci u druge svrhe ne mogu
            koristiti. Ti podaci mogu uključivati Vaše ime i prezime, OIB,
            adresu, broj telefona, email adresu, podatke o zaposlenju i slično.
          </p>
          <br />
          <br />
          <p>
            Geodioba d.o.o. se obvezuje da neće učiniti ni na bilo koji način
            dostupnima Vaše osobne podatke trećima, već će nakon ispunjenja
            svrhe prikupljanja podataka iste obrisati. Vaša je sloboda izbora da
            li ćete nam u takvom slučaju dati svoje podatke ili ne. Upisom
            traženih podataka u za to predviđena polja potvrđujete da ste dali
            privolu odnosno da ste svoje podatke dobrovoljno stavili na
            raspolaganje te da dozvoljavate da se isti koriste u svrhu u koju su
            podaci dani.
          </p>
          <br />
          <br />
          <p>
            U svakom trenutku imate pravo uvida, pravo ispravka, dopune ili
            brisanja datih podataka.
          </p>
          <br />
          <p>
            U svakom trenutku imate pravo odustati o date privole za obradu
            Vaših podataka i zatražiti prestanak njihove daljnje obrade. Vaše
            osobne podatke čuvamo toliko dugo koliko je potrebno da se ispuni
            svrha prikupljanja podataka.
          </p>
          <br />
          <br />
          <p>
            Geodioba d.o.o. koristi kolačiće (cookies). Prema pravilima Europske
            unije obavezni smo zatražiti Vaš pristanak. Korištenjem naših
            stranica pristajete na uporabu kolačića.
          </p>
          <br />
          <br />
          <p>
            Kolačići su malene datoteke koje se pospremaju na računalo dok
            surfamo internetom, obično se to događa kada prvi puta posjetite web
            stranicu. Web stranice pomoću kolačića mogu zapamtiti Vaše
            aktivnosti ili postavke i sljedeći puta kada posjetite istu stranicu
            mogu ih automatski primijeniti. Web stranica “zna” da ste već bili
            ovdje i u nekim slučajevima prilagođava ono što vidite na ekranu.
          </p>
          <br />
          <br />
          <p>
            Mogu se koristiti za spremanje lozinki ili formi koje ste ispunili.
            Generalno gledajući svrha kolačića je dobronamjerna, nisu štetni i
            ne mogu nositi viruse te omogućuju da korisnik ima bolje iskustvo
            prilikom surfanja. Kolačići ne sadržavaju podatke koji Vas mogu
            identificirati, pa je time osigurana i Vaša privatnost.
          </p>
          <br />
          <br />
          <p>
            Geodioba d.o.o. ulaže velike napore da osigura sigurnost osobnih
            podataka i da se isti zaštite od gubitka, uništenja, krivotvorenja i
            neovlaštenog pristupa podacima kao i neovlaštenog izdavanja
            podataka.
          </p>
        </AnimatedBlock>
        <AnimatedBlock
          className="kn-third-section-content-ab kn-pravila-privatnosti"
          delay={400}
        >
          <h3>Cookies</h3>
          <p>
            Kako bi posjet ovoj internetskoj stranici bio što ugodniji,
            funkcionalniji i praktičniji, ova internet stranica sprema na vaše
            računalo određenu količinu informacija, takozvanihcookies(kolačići).
            Oni služe da bi web stranica radila optimalno i kako bi se
            poboljšalo vaše iskustvo pregledavanja i korištenja. Posjetom i
            korištenjem ove web stranice pristajete na upotrebu kolačića koje je
            moguće i blokirati. Nakon toga ćete i dalje moći pregledavati web
            stranicu, ali vam neke mogućnosti neće biti dostupne.
          </p>
          <h4>
            <strong>Što je cookies (kolačić)?</strong>
          </h4>
          <p>
            Kolačić je dio informacija spremljenih na vaše računalo, mobitel ili
            tablet, koje mogu biti dostavljene neposredno od strane web stranice
            koju posjetite (kolačići od prve strane) ili u suradnji i za potrebe
            web stranice od treće strane (kolačići treće strane). Kolačići u
            pravilu spremaju vaše postavke, postavke za web stranicu i sl. Nakon
            što opet otvorite web stranicu, vaš internetski preglednik šalje
            natrag kolačiće koji pripadaju ovoj web stranici. To omogućuje
            stranici da prikaže informacije prilagođene vašim potrebama.
            Kolačići mogu imati širok raspon informacija, uključujući i dio
            osobnih informacija. Takve informacije mogu biti spremljene jedino
            ako vi to omogućite. Sama web stranica ne može dobiti pristup
            informacijama koje im niste dali te ne mogu pristupiti niti jednoj
            drugoj datoteci na vašem računalu.
          </p>
          <h4>
            <strong>Kako ih onemogućiti?</strong>
          </h4>
          <p>
            Želite li onemogućiti spremanje kolačića na svoje računalo, možete
            to učiniti. Sam čin blokiranja mogao bi imati negativan učinak na
            korištenje web stranice. Kako bi isključili kolačiće, potrebno je
            namjestiti postavke i konfiguracije vašeg internetskog preglednika.
            U izborniku preglednika odaberite pomoć i informacije o kolačićima
            te slijedite upute.
            <br />{" "}
          </p>
          <h4>
            <strong>Što su privremeni kolačići?</strong>
          </h4>
          <p>
            Privremeni kolačići ili kolačići sesije uklanjaju se s računala po
            zatvaranju internet preglednika. Pomoću njih web-mjesta pohranjuju
            privremene podatke.
            <br />{" "}
          </p>
          <h4>
            <strong>Što su stalni kolačići?</strong>
          </h4>
          <p>
            Stalni ili spremljeni kolačići ostaju na računalu nakon zatvaranja
            programa internet preglednika. Pomoću njih web-mjesta pohranjuju
            podatke, kao što su ime za prijavu i lozinka, tako da se ne morate
            prijavljivati prilikom svakog posjeta određenom mjestu.
            <br />
            <br />
            <br />
            <br />
            Više o kolačićima možete pročitati ovdje:
            <br />
            <a href="http://www.allaboutcookies.org/">
              http://www.allaboutcookies.org/
            </a>
            <br />
            <a href="http://www.youronlinechoices.com/hr/">
              http://www.youronlinechoices.com/hr/
            </a>
            <br />
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
          </p>
        </AnimatedBlock>
      </div>
    </Section>
  </Layout>
)

export default PravilaPrivatnostiPage
